import React from 'react';
import { createContext } from 'utils/react-helpers';
export type AlertStatus = 'error' | 'success';

const [Provider, useAlertContext] = createContext<{
  status: AlertStatus;
}>();

export const AlertProvider: React.FC<{ status: AlertStatus }> = ({
  children,
  ...props
}) => {
  return <Provider value={props}>{children}</Provider>;
};

export { useAlertContext };
