import React, { HTMLAttributes } from 'react';
import tw, { styled } from 'twin.macro';
import { AlertStatus, AlertProvider } from './context';
import { CloseButton } from 'components/CloseButton';

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  status?: AlertStatus;
  className?: string;
  onClose?: () => void;
}

const Container = styled.div<{ alertStatus: AlertStatus }>`
  ${tw`px-4 py-3 rounded-sm flex relative pr-10 items-center`};

  ${({ alertStatus }) => {
    if (alertStatus === 'error') {
      return tw`bg-red-100`;
    }

    return tw`bg-green-100`;
  }}
`;

export const Alert: React.FC<AlertProps> = ({
  status = 'error',
  children,
  onClose,
  ...rest
}) => {
  return (
    <AlertProvider status={status}>
      <Container {...rest} alertStatus={status}>
        {children}
        {onClose && (
          <div
            css={tw`absolute right-0 top-0 w-8 h-12 flex items-center justify-center`}
          >
            <CloseButton
              label="Đóng"
              size="sm"
              css={tw`opacity-50 hover:opacity-100 transition-opacity`}
              onClick={onClose}
            />
          </div>
        )}
      </Container>
    </AlertProvider>
  );
};
