import React, { HTMLAttributes } from 'react';
import tw, { styled } from 'twin.macro';
import classnames from 'classnames';
import { useAlertContext } from './context';

const Heading = styled.div`
  ${tw`text-base font-medium leading-snug`};
`;

export const AlertTitle = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  const { status } = useAlertContext();

  return React.createElement(Heading, {
    ...props,
    className: classnames(className, {
      'text-green-500': status === 'success',
      'text-red-500': status === 'error',
    }),
  });
};
