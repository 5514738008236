import React from 'react';
import createIcon from './createIcon';

const IconCheck = createIcon(
  <path
    d="M20.5631 4.15731L7.91014 16.6905L3.43687 12.2596C3.22513 12.0499 2.8818 12.0499 2.67001 12.2596L2.15881 12.766C1.94706 12.9757 1.94706 13.3158 2.15881 13.5256L7.52673 18.8427C7.73848 19.0524 8.08181 19.0524 8.2936 18.8427L21.8412 5.42332C22.0529 5.21358 22.0529 4.8735 21.8412 4.66371L21.3299 4.15731C21.1182 3.94756 20.7749 3.94756 20.5631 4.15731Z"
    fill="currentColor"
  />,
);

export default IconCheck;
