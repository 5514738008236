import React from 'react';
import tw, { styled } from 'twin.macro';
import { IconProps } from 'components/icons/createIcon';
import { useAlertContext } from './context';
import IconClose from 'components/icons/IconClose';
import IconCheck from 'components/icons/IconCheck';

const Box = styled.div`
  ${tw`w-4 h-4 inline-flex justify-center items-center rounded-full text-white mr-4 flex-none`};
`;

export const AlertIcon = (props: IconProps) => {
  const { status } = useAlertContext();

  if (status === 'error') {
    return (
      <Box css={tw`bg-red-500`}>
        <IconClose size={10} {...props} />
      </Box>
    );
  }

  return (
    <Box css={tw`bg-green-500`}>
      <IconCheck size={10} {...props} />
    </Box>
  );
};
