import React from 'react';
import toaster, { Position } from 'toasted-notes';
import { Alert, AlertTitle, AlertIcon, AlertProps } from 'components/Alert';
import tw from 'twin.macro';

interface ToastProps {
  id?: string;
  className?: string;
  onClose?: () => void;
  status?: AlertProps['status'];
  title: string;
  duration?: number | null;
  position?: keyof typeof Position;
}

interface RenderOption {
  render?: (props: { onClose: () => void; id: string }) => React.ReactNode;
}

interface UseToastOptions extends ToastProps, RenderOption {
  closeable?: boolean;
}

const Toast = (props: ToastProps) => {
  const { id, onClose, title, position = 'top', ...rest } = props;

  return (
    <Alert
      onClose={onClose}
      id={id}
      css={[
        tw`shadow-toast mx-4 text-left`,
        !position || position.includes('top') ? tw`mt-4` : tw`mb-4`,
        props.status === 'success' ? tw`text-green-500` : tw`text-red-500`,
      ]}
      {...rest}
    >
      <AlertIcon />
      <AlertTitle>{title}</AlertTitle>
    </Alert>
  );
};

export function useToast() {
  const notify = React.useCallback(
    ({
      position,
      closeable = true,
      duration = 5000,
      render,
      onClose: handleOnClose,
      ...rest
    }: UseToastOptions) => {
      // Options for the toaster lib
      const options = {
        position,
        duration,
      };

      if (render) {
        return toaster.notify(
          ({ onClose, id }) => (
            <>
              {render({
                onClose: () => {
                  onClose();

                  if (handleOnClose) {
                    handleOnClose();
                  }
                },
                id,
              })}
            </>
          ),
          options,
        );
      }

      toaster.notify(
        ({ onClose, id }) => (
          <Toast
            {...{
              onClose: closeable
                ? () => {
                    onClose();

                    if (handleOnClose) {
                      handleOnClose();
                    }
                  }
                : undefined,
              id,
              position,
              ...rest,
            }}
          />
        ),
        options,
      );

      return null;
    },
    [],
  );

  return notify;
}
